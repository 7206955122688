import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { DataGrid, GridColDef, GridSortDirection, GridValueFormatterParams } from '@material-ui/data-grid';
import PlayIcon from "@material-ui/icons/PlayArrow";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { User } from "./App";
import EditAudio from "./EditAudio";
import ConfirmDialog from "./ConfirmDialog";

import config from "./config.json";
import { useFeedback } from "./Feedback";
import { HTTP, HTTPMethod } from "./Backend";
import { Link, Route, Routes } from "react-router-dom";
import AudioPlayer from "./AudioPlayer";

interface AudioProperties {
    user: User;
}

const useStyles = makeStyles((theme: Theme) => ({
    song: {
        margin: theme.spacing(1)
    },
    error: {
        color: theme.palette.error.main
    },  
    datagrid: {
        color: theme.palette.text.secondary
    },
    buttoninput: {
        margin: theme.spacing(1)
    },
    iconbutton: {
        margin: 0
    }
}));

export interface AudioItem {
    id: number;
    songname: string;
    audiofilename: string;
    date: Date;
    comments: string;
}

function Audio(props: AudioProperties) {

  const classes = useStyles();

  const [busy, setBusy] = useState<boolean>(false);
  const [audios, setAudios] = useState<AudioItem[]>([]);
  const [currentAudioId, setCurrentAudioId] = useState<number | null>(null);
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [deleteCandidateId, setDeleteCandidateId] = useState<number | null>(null);
  
  const feedback = useRef(useFeedback());

  useEffect(() => {
        setBusy(true);
        HTTP(HTTPMethod.GET, config.urlAllAudio)
        .then(json => {
                let newAudios: AudioItem[] = [];
                for (let record of json) {
                    const row:AudioItem = {
                      id: record.audioid,
                      songname: record.songname,
                      audiofilename: record.audiofilename,
                      date: record.date,
                      comments: record.comments
                    };
                    newAudios.push(row);
                }

                setAudios(newAudios);

        })
        .catch(error => {
            feedback.current(error);
        })
        .finally(() => {
            setBusy(false);
        });
  }, [reloadCount]);


  const deleteAudio = (id: number | null) => {
    setBusy(true);
    HTTP(HTTPMethod.DELETE, config.urlDeleteAudio + '/' + id, props.user.token)
    .then(() => {
        setReloadCount(reloadCount + 1);
    })
    .catch(error => {
        feedback.current(error);
    })
    .finally(() => {
        setBusy(false);
    });
};

  const columns: GridColDef[] = [
    { field: "id", headerName: "#", width: 100 },
    { field: "songname", headerName: "Nummer", width: 240 },
    { field: "audiofilename", headerName: "Bestandsnaam", width: 240 },
    { field: "date", headerName: "Datum", width: 120 },
    { field: "comments", headerName: "Opmerkingen", width: 360 },
    { field: "action", headerName: "Actie", width: 230,
      renderCell: (params: GridValueFormatterParams) => (
        <span>
          <Tooltip title="Beluister opname">
            <Link to={"/audio/" + params.row.id}>
                <IconButton
                    color="secondary"
                    className={classes.iconbutton}
                > 
                <PlayIcon/>
                </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton
                color="secondary"
                className={classes.iconbutton}
                href={config.urlSingleAudio + '/' + params.row.id}
            > 
                <DownloadIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Verwijderen">
            <span>
                <IconButton
                    color="secondary"
                    className={classes.iconbutton}
                    disabled={props.user.token === null}
                    onClick={() => setDeleteCandidateId(params.row.id as number)}
                > 
                    <DeleteIcon/>
                </IconButton>
            </span>
          </Tooltip>
        </span>
      )
    }
  ];


  const handleAddAudio = () => {
      setCurrentAudioId(0);
  }

  const handleCloseEditDialog = () => {
      setReloadCount(reloadCount + 1);  // Force reload of the data.
      setCurrentAudioId(null);
  }

  const deleteCandidateAudio = audios.find((audio: AudioItem) => { return audio.id === deleteCandidateId });
  const deleteCandidateName = deleteCandidateAudio !== undefined ? deleteCandidateAudio.audiofilename : "ERROR";

  return (
      <section className={classes.song}>

        <EditAudio audioid={currentAudioId} user={props.user} onClose={handleCloseEditDialog}/>
    
        <Routes>
            <Route path=":audioid" element={<AudioPlayer/>}/>
        </Routes>

        <ConfirmDialog
            title="Bevestig verwijderen mp3"
            open={deleteCandidateId !== null}
            onConfirm={() => {deleteAudio(deleteCandidateId)}}
            onClose={() => setDeleteCandidateId(null)}
        >
            Weet je zeker dat je {deleteCandidateName} wilt verwijderen?
        </ConfirmDialog>

        <div style={{ height: 800, width: '100%' }}>
            <DataGrid
            className={classes.datagrid}
            loading={busy}
            rows={audios} 
            columns={columns}
            autoPageSize
            sortModel={[
                {
                    field: 'date',
                    sort: 'desc' as GridSortDirection,
                }]}
            />
        </div>

        <Button
            className={classes.buttoninput}
            color="secondary"
            variant="contained"
            disabled={props.user.token === null}
            onClick={handleAddAudio}>
                Nieuwe opname uploaden
        </Button>
      </section>
  );
}

export default Audio;

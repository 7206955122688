import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from "@material-ui/core";
import { BrowserRouter } from 'react-router-dom';
import { FeedbackProvider } from './Feedback';

const theme = createTheme({
    palette: {
        type: "dark",     
        primary: {        
            main: "#000000"
        },
        secondary: {
            main: "#e53935"            
        }
    }
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <ThemeProvider theme={theme}>
        <FeedbackProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </FeedbackProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

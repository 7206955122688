import React, { useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import config from "./config.json";
import { HTTP, HTTPMethod } from "./Backend";

type onCloseHandler = () => void;

interface AboutProperties {
  isOpen: boolean;
  onClose: onCloseHandler;
}

interface AboutInfo {
  application: string;
  version: string;
  buildAt: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrong: {
    display: "block",
    margin: theme.spacing(2),
    color: "red"
  },
  login: {
    display: "block",
    margin: theme.spacing(2),
  },
  loginButton: {
    color: "white",
    margin: theme.spacing(1)
  },
  textinput: {
    display: "block",
    margin: theme.spacing(1),
    width: 400
  },
  buttoninput: {
    margin: theme.spacing(1)
  },
  accountCircle: {
    marginRight: theme.spacing(1)
  }
}));


function About(props: AboutProperties) {

  const classes = useStyles();

  const [info, setInfo] = useState<AboutInfo>({
    application: "",
    version: "",
    buildAt: ""
  });
  const [busy, setBusy] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  
  // Get information from backend.
  useEffect(() => {
    setBusy(true);
    setError(false);
    HTTP(HTTPMethod.GET, config.urlInfo, null, {})
    .then(json => {
        setInfo({
          application: json.application,
          buildAt: json.buildAt,
          version: json.version
        });
    })
    .catch(() => {
        setError(true);
    })
    .finally(() => {
      setBusy(false);
    });
  }, []);

  return (
    <div>
        <Dialog open={props.isOpen} onClose={props.onClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Info over deze app</DialogTitle>
          <DialogContent>

            <table>
              <tbody>
              <tr>
                <td>Applicatie:</td>
                <td>{info.application}</td>
              </tr>
              <tr>
                <td>Versie:</td>
                <td>{info.version}</td>
              </tr>
              <tr>
                <td>Bouwdatum:</td>
                <td>{info.buildAt}</td>
              </tr>
              </tbody>
            </table>

            {busy && (
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>
                  <CircularProgress />
              </div>
            )}

            {(error) && (
              <p className={classes.wrong}>Er is iets mis met de server waar dit programma op draait. Probeer het later nog eens.</p>
            )}
                
            </DialogContent>
            <DialogActions>
                <Button
                  className={classes.buttoninput}
                  color="secondary"
                  variant="contained"
                  onClick={props.onClose}>
                    Sluiten
                </Button>
              </DialogActions>
        </Dialog>
      </div>
  );
}

export default About;
import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import AttachmentIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";

import { User } from "./App";

import config from "./config.json";
import { IconButton, Typography } from "@material-ui/core";
import { useFeedback } from "./Feedback";
import { HTTP, HTTPMethod, HTTPPostForm } from "./Backend";

interface EditAttachmentProperties {
  songid: number | null;
  user: User;
  onAddAttachment: (attachmentid:number) => void;
  onDeleteAttachment: (attachmentid:number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
      color: theme.palette.error.main
  },
  table: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  noBorder: {
    borderBottom: "none"
  },
  listitem: {
    display: "inline",
    margin: theme.spacing(1)
  },
  link: {
      color: theme.palette.text.primary
  },
  buttoninput: {
    margin: theme.spacing(1)
  },
  accountCircle: {
    marginRight: theme.spacing(1)
  },
  hiddeninput: {
      display: "none"
  }
}));

export interface Attachment {
    attachmentid: number;
    attachmentfilename: string;
};

export default function EditAttachments(props: EditAttachmentProperties) {

  const classes = useStyles();

  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [busy, setBusy] = useState<boolean>(false);

  const feedback = useRef(useFeedback());

  useEffect(() => {
    if ((props.songid === 0) || (props.songid === null)) {
        setAttachments([]);
    } else  {
        setBusy(true);
        HTTP(HTTPMethod.GET, config.urlGetAttachments + "/" + props.songid)
        .then(json => {
            let result:Attachment[] = [];
            for (let record of json) {
                const row:Attachment = {
                  attachmentid: record.attachmentid,
                  attachmentfilename: record.attachmentfilename
                };
                result.push(row);
            }
            setAttachments(result);
        })
        .catch(error => {
            feedback.current(error);
        })
        .finally(() => {
            setBusy(false);
        });
    }
}, [props.songid]);


  const onFileSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setBusy(true);
    if (event.target.files !== null) {
        const formData:FormData = new FormData();
        const filename:string = event.target.files[0].name;
        formData.append("file", event.target.files[0], filename);

        HTTPPostForm(config.urlPostAttachment + '/' + props.songid, props.user.token, formData)
        .then(json => {
            const attachmentid:number = json.attachmentid;
            props.onAddAttachment(attachmentid);
            let ats:Attachment[] = attachments;
            ats.push({
                attachmentid: attachmentid,
                attachmentfilename: filename
            })
            setAttachments(ats);
        })
        .catch(error => {
            feedback.current(error);
        })
        .finally(() => {
            setBusy(false);
        });
    }
  }

  const handleDelete = (attachmentid:number) => {

    setBusy(true);
    HTTP(HTTPMethod.DELETE, config.urlDeleteAttachment + '/' + attachmentid, props.user.token)
    .then(() => {
            props.onDeleteAttachment(attachmentid);
            let ats:Attachment[] = [];
            attachments.forEach((attachment) => {
                if (attachment.attachmentid !== attachmentid) {
                    ats.push(attachment);
                }
            })
            setAttachments(ats);
    })
    .catch(error => {
        feedback.current(error);
    })
    .finally(() => {
        setBusy(false);
    });

  }

return (
    <div>
        <Typography variant="h6">
            Bijlagen:
        </Typography>

        {busy && (
            <div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>
                <CircularProgress />
            </div>
        )}

        <Table className={classes.table} size="small">
            <TableBody>
            {attachments.map((attachment) => {
                return (
                    <TableRow key={attachment.attachmentid}>
                        <TableCell className={classes.noBorder}>
                            <AttachmentIcon/>
                            <Typography className={classes.listitem}>
                                <Link className={classes.link} href={config.urlGetAttachment + "/" + attachment.attachmentid}>
                                    {attachment.attachmentfilename}
                                </Link>
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.noBorder}>
                            <IconButton
                                onClick={() => handleDelete(attachment.attachmentid)}
                            >
                                <DeleteIcon color="secondary"/>
                            </IconButton>
                        </TableCell>
                    </TableRow>);
            })}
            </TableBody>
        </Table>
        <input
            accept="application/pdf,audio/*"
            className={classes.hiddeninput}
            id="contained-button-file"
            type="file"
            onChange={onFileSelectionChange}
        />
        <label htmlFor="contained-button-file">
            <Button 
                className={classes.buttoninput}
                variant="contained" 
                color="secondary"
                component="span"
            >
                <AddIcon/>
                Upload bijlage
            </Button>
        </label>
    </div>
  );
}
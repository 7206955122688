import React, { createContext, useContext, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Backdrop, DialogActions } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { Button } from "@material-ui/core";
import FeedbackIcon from "@material-ui/icons/Feedback";

type DispatchType = (message: string) => void;

const FeedbackContext = createContext<DispatchType | undefined>(undefined);

function useFeedback() {
    const context = useContext(FeedbackContext);
    if (context === undefined) {
        throw new Error("Feedback dispatch context not defined. Use Feedback only within provider.");
    }
    return context;
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        margin: theme.spacing(1),
    },
    divicon: {
        display: "inline-block",
        background: "blue"
    },
    divmessage: {
        display: "inline-block",
        background: "green",
        width: 100,
        position: "relative",
        top: -25
    },
    icon: {
        color: theme.palette.secondary.main,
        margin: theme.spacing(1),
        verticalAlign: "top"
    },
    button: {
        margin: theme.spacing(1)
    }
}));


function FeedbackProvider({children}:{children: React.ReactNode}) {

    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    const dispatcher:DispatchType = (message: string) => {
        setMessage(message);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const classes = useStyles();

    return (
        <FeedbackContext.Provider value={dispatcher}>
            <Backdrop open={open} onClick={handleClose}>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle className={classes.title}>
                        Feedback
                    </DialogTitle>
                    <DialogContent>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <FeedbackIcon 
                                            className={classes.icon}
                                            fontSize="large"
                                        />
                                    </td>
                                    <td>
                                        {message}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary" 
                            onClick={handleClose}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Backdrop>
            {children}
        </FeedbackContext.Provider>
    );
}

export { FeedbackProvider, useFeedback }
import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { User } from "./App";
import { TextField } from "@material-ui/core";
import config from "./config.json";
import { useFeedback } from "./Feedback";
import { HTTP, HTTPMethod } from "./Backend";

interface EditPasswordProperties {
  user: User;
  show: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttoninput: {
    margin: theme.spacing(1)
  },
  wrong: {
    color: theme.palette.error.main
  },
  lockicon: {
    position: "relative",
    top: theme.spacing(1)
  }
}));


function EditPassword(props: EditPasswordProperties) {

  const classes = useStyles();

  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [checkPassword, setCheckPassword] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(false);

  const feedback = useRef(useFeedback());

  useEffect(() => {
    if (props.show) {
        setOldPassword("");
        setNewPassword("");
        setCheckPassword("");
    }
  }, [props.show]);

  const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(event.target.value);
  }

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  }

  const handleCheckPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckPassword(event.target.value);
  }

  const isNewPasswordValid = () => {
      return (newPassword.length >= 8);
  }

  const arePasswordsValidAndEqual = () => {
      return (isNewPasswordValid() && (newPassword === checkPassword));
  }

  const handleSave = () => {
    setBusy(true);
    const contents = ({
      "userid": props.user.userid,
      "oldpassword": oldPassword,
      "newpassword": newPassword
    });
    HTTP(HTTPMethod.POST, config.urlSetPassword, props.user.token, contents)
    .then(() => {
        props.onClose();
    })
    .catch(error => {
      feedback.current(error);
    })
    .finally(() => {
      setBusy(false);
    });
  }

  return (
    <div>
        <Dialog open={props.show} onClose={props.onClose}>
          <DialogTitle id="form-dialog-title">Wachtwoord aanpassen</DialogTitle>
          <DialogContent>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            Oud wachtwoord
                        </TableCell>
                        <TableCell>
                          <TextField
                            id="oldpassword"
                            type="password"
                            variant="outlined"
                            autoComplete="off"
                            value={oldPassword}
                            onChange={handleOldPasswordChange}
                          />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Nieuw wachtwoord
                        </TableCell>
                        <TableCell>
                        <TextField
                            id="newpassword"
                            autoComplete="off"
                            type="password"
                            variant="outlined"
                            value={newPassword}
                            error={!isNewPasswordValid()}
                            onChange={handleNewPasswordChange}
                          />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Herhaal wachtwoord
                        </TableCell>
                        <TableCell>
                        <TextField
                            id="checkpassword"
                            autoComplete="off"
                            type="password"
                            variant="outlined"
                            value={checkPassword}
                            error={!arePasswordsValidAndEqual()}
                            onChange={handleCheckPasswordChange}
                          />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {busy && (
                <div style={{display: "flex", justifyContent: "center", marginTop: 100}}>
                  <CircularProgress />
              </div>
            )}

          </DialogContent>

          <DialogActions>
            <Button
                className={classes.buttoninput}
                color="secondary"
                variant="contained"
                onClick={props.onClose}>
                    Annuleren
            </Button>
            <Button
                className={classes.buttoninput}
                color="secondary"
                variant="contained"
                disabled={!isNewPasswordValid() || !arePasswordsValidAndEqual()}
                onClick={handleSave}>
                    Opslaan
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default EditPassword;
import React, { useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { User } from "./App";

import config from "./config.json";
import { useFeedback } from "./Feedback";
import { HTTP, HTTPMethod } from "./Backend";

// Regex to check if time is valid.
const reTime = new RegExp("^([0-9]{1,2}):([0-9]{2})(:[0-9]{2})*");

type onReloadRequestHandler = () => void;

export type SetEventsVariants = "SetAvailable" | "SetNotAvailable" | "SetRehearse" | "SetNotRehearse";

interface SetEventsProperties {
  variant: SetEventsVariants;
  user: User;
  selectedDates: string[];
  onReloadRequest: onReloadRequestHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrong: {
    display: "block",
    margin: theme.spacing(2),
    color: theme.palette.error.main
  },
  textinput: {
    display: "block",
    margin: theme.spacing(1),
    width: 400
  },
  buttoninput: {
    margin: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(1)
  },
  accountCircle: {
    marginRight: theme.spacing(1)
  }
}));


function SetEvents(props: SetEventsProperties) {

  const classes = useStyles();

  const [fromtime, setFromtime] = useState<string>("19:30");
  const [totime, setTotime] = useState<string>("23:59");
  const [comments, setComments] = useState<string>("");
  const [dialogOpen, setdialogOpen] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(false);

  const feedback = useRef(useFeedback());

  const handleSetEventsButton = () => {
    setdialogOpen(true);
  }

  const handleCancelButton = () => {
    setdialogOpen(false);
    setBusy(false);
  }

  const handleFromtimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFromtime(event.target.value);
  };
  const handleTotimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setTotime(event.target.value);
  };

  const handleCommentsInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };

  const isFromtimeValid = ():boolean => {
      return reTime.test(fromtime);
  }

  const isTotimeValid = ():boolean => {
    return reTime.test(totime);
  }

// User has entered dates and times. Post them.
  const handleSetAvailable = () => {
    setBusy(true);
    const contents = ({
      "action": props.variant as string,
      "dates": props.selectedDates,
      "fromtime": fromtime,
      "totime": totime,
      "comments": comments
    });
    HTTP(HTTPMethod.POST, config.urlSetAvailable, props.user.token, contents)
    .then(() => {
        props.onReloadRequest();
        handleCancelButton();
    })
    .catch(error => {
      feedback.current(error);
    })
    .finally(() => {
      setBusy(false);
    });
  };

  // Catch enter keypress.
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if ((event.key === "Enter") && isFromtimeValid() && isTotimeValid()) {
      handleSetAvailable();
    }
  }
   
  const title:string = props.variant === "SetAvailable" ? "Beschikbaar" :
     props.variant === "SetNotAvailable" ? "Niet Beschikbaar" :
     props.variant === "SetRehearse" ? "Oefenen" : "Niet Oefenen";

  const description:string = props.variant === "SetAvailable" ? "Geef op hoelaat je beschikbaar bent." :
     props.variant === "SetNotAvailable" ? "Bevestig dat je niet beschikbaar bent." :
     props.variant === "SetRehearse" ? "Geef op hoe laat we gaan oefenen." : "Bevestig dat we niet gaan oefenen.";

  return (
    <div>

        {
          <Button 
            className={classes.buttoninput} 
            variant="contained" color="secondary" 
            disabled={(props.user.token === null) || (props.selectedDates.length === 0)}
            onClick={handleSetEventsButton}
          >{title}
          </Button>
        }

        <Dialog open={dialogOpen} aria-labelledby="form-dialog-title" onKeyPress={handleKeyPress} onClose={handleCancelButton}>
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {description} 
            </DialogContentText>

                {props.selectedDates.map((date:string) => {return (<Chip className={classes.chip} key={date} label={date}/>);})}

                {(props.variant === "SetAvailable" || props.variant === "SetRehearse") && (<TextField
                  className={classes.textinput}
                  id="fromtime"
                  label="Vanaf"
                  variant="outlined"
                  fullWidth
                  disabled={busy}
                  value={fromtime}
                  error={!isFromtimeValid()}
                  onChange={handleFromtimeInputChange}
                />)}

                {(props.variant === "SetAvailable" || props.variant === "SetRehearse") && (<TextField
                  className={classes.textinput}
                  id="totime"
                  label="Tot"
                  variant="outlined"
                  fullWidth
                  disabled={busy}
                  value={totime}
                  error={!isTotimeValid()}
                  onChange={handleTotimeInputChange}
                />)}

                {(props.variant === "SetAvailable" || props.variant === "SetNotAvailable" || props.variant === "SetRehearse") && (<TextField
                  className={classes.textinput}
                  id="comments"
                  label="Opmerkingen"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  disabled={busy}
                  value={comments}
                  onChange={handleCommentsInputChange}
                />)}
                        
                {busy && (
                   <div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>
                      <CircularProgress />
                  </div>
                )}

              </DialogContent>
              <DialogActions>
                <Button
                    className={classes.buttoninput}
                    color="secondary"
                    variant="contained"
                    onClick={handleCancelButton}>
                      Annuleer
                  </Button>
                  <Button
                    className={classes.buttoninput}
                    color="secondary"
                    variant="contained"
                    disabled={!isFromtimeValid() || !isTotimeValid()}
                    onClick={handleSetAvailable}>
                      {title}
                  </Button>
                </DialogActions>
        </Dialog>
      </div>
  );
}

export default SetEvents;
import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import AttachmentIcon from "@material-ui/icons/AttachFile";

import config from "./config.json";
import { useFeedback } from "./Feedback";
import { HTTP, HTTPMethod } from "./Backend";

interface ShowAttachmentProperties {
  songid: number | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    margin: theme.spacing(1),
    color: theme.palette.text.primary
  }
}));

export interface Attachment {
    attachmentid: number;
    attachmentfilename: string;
};

export default function ShowAttachments(props: ShowAttachmentProperties) {

  const classes = useStyles();
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const feedback = useRef(useFeedback());

  useEffect(() => {
    if ((props.songid === 0) || (props.songid === null)) {
        setAttachments([]);
    } else  {
        HTTP(HTTPMethod.GET, config.urlGetAttachments + "/" + props.songid)
        .then(json => {
            let result:Attachment[] = [];
            for (let record of json) {
                const row:Attachment = {
                  attachmentid: record.attachmentid,
                  attachmentfilename: record.attachmentfilename
                };
                result.push(row);
            }
            setAttachments(result);
        })
        .catch(error => {
            feedback.current(error);
        })
    }
  }, [props.songid]);


  return (
    <div>
        {attachments.map((attachment) => {
            return (
                <Typography key={attachment.attachmentid}>
                    <IconButton
                        href={config.urlGetAttachment + "/" + attachment.attachmentid}
                    >
                        <AttachmentIcon/>
                    </IconButton>
                    <Link className={classes.link} href={config.urlGetAttachment + "/" + attachment.attachmentid}>
                        {attachment.attachmentfilename}
                    </Link>
                </Typography>
            )}
        )}
    </div>
  );
}
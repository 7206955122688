import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { User } from "./App";
import { TextField } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import config from "./config.json";
import { useFeedback } from "./Feedback";
import { HTTP, HTTPMethod } from "./Backend";

interface EditUserProperties {
  user: User;
  show: boolean;
  onSetUser: (user:User) => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttoninput: {
    margin: theme.spacing(1)
  },
  wrong: {
    color: theme.palette.error.main
  },
  lockicon: {
    position: "relative",
    top: theme.spacing(1)
  }
}));


function EditUser(props: EditUserProperties) {

  const classes = useStyles();

  const [editEnable, setEditEnable] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [realname, setRealname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(false);

  const feedback = useRef(useFeedback());

  useEffect(() => {
    if (props.show) {
      setEditEnable(false);
      setUsername(props.user.username);
      setRealname(props.user.realname);
      setEmail(props.user.email);
    }
  }, [props.show, props.user]);

  const handleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditEnable(event.target.checked);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  }

  const handleRealnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRealname(event.target.value);
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const handleSave = () => {
    setBusy(true);
    const contents = ({
      "userid": props.user.userid,
      "username": username,
      "realname": realname,
      "email": email
    });
    HTTP(HTTPMethod.POST, config.urlSetUser, props.user.token, contents)
    .then(() => {
        props.onSetUser({
          userid: props.user.userid,
          username: username,
          realname: realname,
          role: props.user.role,
          token:  props.user.token,
          email: email,
          tokenexpirytime: props.user.tokenexpirytime
        });
        props.onClose();
    })
    .catch(error => {
      feedback.current(error);
    })
    .finally(() => {
      setBusy(false);
    });
  }

  return (
    <div>
        <Dialog open={props.show} onClose={props.onClose}>
          <DialogTitle id="form-dialog-title">Gegevens gebruiker</DialogTitle>
          <DialogContent>

            <Table>
                <TableBody>
                    <TableRow>
                      <TableCell>
                        Ontgrendelen
                      </TableCell>
                      <TableCell>
                        <Switch 
                          checked={editEnable}
                          onChange={handleEditChange}
                        />
                        {editEnable ? (<LockOpenIcon className={classes.lockicon}/>) : (<LockIcon className={classes.lockicon}/>)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Gebruikersnaam
                        </TableCell>
                        <TableCell>
                          <TextField
                            id="username"
                            autoComplete="off"
                            value={username}
                            disabled={!editEnable}
                            onChange={handleUsernameChange}
                          />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Eigen naam
                        </TableCell>
                        <TableCell>
                        <TextField
                            id="realname"
                            autoComplete="off"
                            value={realname}
                            disabled={!editEnable}
                            onChange={handleRealnameChange}
                          />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Email
                        </TableCell>
                        <TableCell>
                        <TextField
                            id="email"
                            autoComplete="off"
                            value={email}
                            disabled={!editEnable}
                            onChange={handleEmailChange}
                          />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {busy && (
                <div style={{display: "flex", justifyContent: "center", marginTop: 100}}>
                  <CircularProgress />
              </div>
            )}

          </DialogContent>

          <DialogActions>
            <Button
                className={classes.buttoninput}
                color="secondary"
                variant="contained"
                onClick={props.onClose}>
                    Annuleren
            </Button>
            <Button
                className={classes.buttoninput}
                color="secondary"
                variant="contained"
                disabled={!editEnable}
                onClick={handleSave}>
                    Opslaan
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default EditUser;
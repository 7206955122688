type resolveHandler = (value:any) => void;
type rejectHandler = (value:string) => void;

export enum HTTPMethod {
    GET = "get",
    POST = "post",
    DELETE = "delete"
}

export interface HTTPParameters {
    [key:string] : string | string[] | number | number[] | null
}

// Handle GET parameters.
const addParamsToUrl = (url:string, params:HTTPParameters):string => {
    let pstr:string = Object.keys(params).reduce((ac, it) => { return ac + '&' + it + '=' + params[it]}, "");
    if (pstr.length > 0) pstr = '?' + pstr.substr(1);
    return url + pstr;
}


export function HTTP(method:HTTPMethod, url:string, token?:string | null, params?:HTTPParameters):Promise<any> {
    return new Promise((resolve:resolveHandler, reject:rejectHandler) => {
        const headers = ({
            "Accept": "application/json",
            "Content-type": "application/json",
            "token": (typeof token === "string") ? token : "null"
        });
        let request:RequestInit = {
            mode : "cors",
            method: method,
            headers: headers
        };
        if (method === HTTPMethod.POST) {
            if (params !== undefined) {
                request.body = JSON.stringify(params);
            }
        }
        fetch((method === HTTPMethod.GET) 
                ? addParamsToUrl(url, (params !== undefined) ? params : {})
                : url
            , request)
        .then(result => {
            if (result.status === 200) {
                return result.json();
            } else {
                throw new Error("" + result.status + ": " + result.statusText);
            }
        })
        .then(json => {
            resolve(json);
        })
        .catch(error => {
            reject("" + error);
        });
    });
}

export function HTTPPostForm(url:string, token:string | null, form:FormData):Promise<any> {
    return new Promise((resolve:resolveHandler, reject:rejectHandler) => {
        const headers = ({
            "Accept": "application/json",
            "token": (typeof token === "string") ? token : "null"
        });
        let request:RequestInit = {
            mode : "cors",
            method: "post",
            headers: headers,
            body: form
        };
        fetch(url, request)
        .then(result => {
            if (result.status === 200) {
                return result.json();
            } else {
                throw new Error("" + result.status + ": " + result.statusText);
            }
        })
        .then(json => {
            resolve(json);
        })
        .catch(error => {
            reject("" + error);
        });
    });
}

export function HTTPText(method:HTTPMethod, url:string, token?:string | null, params?:HTTPParameters, form?:FormData):Promise<any> {
    return new Promise((resolve:resolveHandler, reject:rejectHandler) => {
        const headers = ({
            "Accept": "text/html",
            "Content-type": "text/html",
            "token": (typeof token === "string") ? token : "null"
        });
        let request:RequestInit = {
            mode : "cors",
            method: method,
            headers: headers
        };
        if (method === HTTPMethod.POST) {
            if (form === undefined) {
                request.body = JSON.stringify(params);
            } else {
                request.body = form;
            }
        }
        fetch((method === HTTPMethod.GET) 
                ? addParamsToUrl(url, (params !== undefined) ? params : {})
                : url
            , request)
        .then(result => {
            if (result.status === 200) {
                return result.text();
            } else {
                throw new Error("" + result.status + ": " + result.statusText);
            }
        })
        .then(text => {
            resolve(text);
        })
        .catch(error => {
            reject("" + error);
        });
    });
}
import { useEffect } from "react";
import config from "./config.json";
import { useParams } from "react-router-dom";

function AudioPlayer() {

  let { audioid } = useParams<{audioid:string}>();

  useEffect(() => {
    let audio:any = document.getElementById("audio");
    let audioSource:any = document.getElementById("audioSource");
      if (audio !== null) {
        audioSource.src = config.urlSingleAudio + "/" + audioid;
        audio.load();
        audio.play();
      }
  }, [audioid]);


  return (
    <div>
      <audio id="audio" controls>
          <source id="audioSource" src="" type="audio/mpeg"/>
      </audio>
    </div>
  );
}

export default AudioPlayer;
import React, { ReactNode } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

interface ConfirmDialogProps {
    title: string;
    children: ReactNode;
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => props.onClose()}
          color="secondary"
        >
          Nee
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            props.onClose();
            props.onConfirm();
          }}
          color="secondary"
        >
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
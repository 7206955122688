import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";

import { User } from "./App";

import config from "./config.json";
import { DialogContent } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SongItem } from "./Songs";
import { useFeedback } from "./Feedback";
import { HTTP, HTTPMethod, HTTPPostForm } from "./Backend";


interface EditAudioProperties {
  audioid: number | null;
  user: User;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
      color: theme.palette.error.main
  },
  textinput: {
    display: "block",
    margin: theme.spacing(1),
  },
  buttoninput: {
    margin: theme.spacing(1)
  },
  accountCircle: {
    marginRight: theme.spacing(1)
  },
  hiddeninput: {
      display: "none"
  }
}));

export interface Audio {
    audioid: number;
    audiofilename: string;
};

export default function EditAAudio(props: EditAudioProperties) {

  const classes = useStyles();

  const [busy, setBusy] = useState<boolean>(false);
  const [songs, setSongs] = useState<SongItem[]>([]);
  const [comments, setComments] = useState<string>("");
  const [song, setSong] = useState<SongItem | null>(null);

  const feedback = useRef(useFeedback());

  useEffect(() => {
    if (props.audioid === 0) {
        setBusy(true);
        setSong(null);
        HTTP(HTTPMethod.GET, config.urlSongs)
        .then(json => {
                let newSongs: SongItem[] = [];
                for (let record of json) {
                    const row:SongItem = {
                        id: record.songid,
                        songname: record.songname,
                        date: record.date,
                        comments: record.comments
                    };
                    newSongs.push(row);
                }
                setSongs(newSongs);
        })
        .catch(error => {
            feedback.current(error);
        })
        .finally(() => {
            setBusy(false);
        });
    }
}, [props.audioid]);

  const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setComments(event.target.value);
  }

  const handleSongChange = (event: any, selectedSong: SongItem | null) => {
    setSong(selectedSong);
  }

const onFileSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setBusy(true);

    const formData:FormData = new FormData();

    if (event.target.files !== null) {
        const filename:string = event.target.files[0].name;
        formData.append("file", event.target.files[0], filename);
        formData.append("comments", comments);
        if (song !== null) {
            formData.append("songid", song.id.toString());
        }

        HTTPPostForm(config.urlPostAudio, props.user.token, formData)
        .then(() => {
            props.onClose();
        })
        .catch(error => {
            feedback.current(error);
        })
        .finally(() => {
            setBusy(false);
        });
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" open={props.audioid !== null} onClose={props.onClose}>
        <DialogTitle>
            Audio uploaden
        </DialogTitle>
        <DialogContent>
            {busy && (
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>
                    <CircularProgress />
                </div>
            )}

            <Autocomplete
                id="choose-song"
                options={songs}
                getOptionLabel={(song) => song.songname}
                value={song}
                renderInput={(params) => 
                <TextField 
                    className={classes.textinput} 
                    {...params} 
                    label="Nummer" 
                    variant="outlined" 
                />}
                onChange={handleSongChange}
            />

            <TextField
                className={classes.textinput}
                id="comments"
                label="Opmerkingen"
                variant="outlined"
                fullWidth
                disabled={busy}
                value={comments}
                onChange={handleCommentsChange}
            />
      
            <input
                accept="audio/*"
                className={classes.hiddeninput}
                id="contained-button-file"
                type="file"
                onChange={onFileSelectionChange}
            />
            <label htmlFor="contained-button-file">
                <Button 
                    className={classes.buttoninput}
                    variant="contained" 
                    color="secondary"
                    component="span"
                >
                    <AddIcon/>
                    Upload MP3
                </Button>
            </label>
        </DialogContent>
   </Dialog>
  );
}
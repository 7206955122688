import React, { useState } from "react";
import { Route, Routes, Link, useLocation } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import LinkBase from "@material-ui/core/Link";
import Login from "./Login";
import Calendar from "./Calendar";
import Songs from "./Songs";
import Audio from "./Audio";
import About from "./About";
import Paper from "@material-ui/core/Paper";


const useStyles = makeStyles((theme: Theme) => ({
  error: {
    display: "block",
    margin: theme.spacing(2),
    color: "red"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  buttoninput: {
    margin: theme.spacing(1),
    textDecoration: "none"
  },
  title: {
    display: "none",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    }
  },
  user: {
    margin: theme.spacing(1)
  },
  paper: {
    margin: theme.spacing(3)
  },
  header: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(3)
  },
  footer: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary
  },
  paragraph: {
    margin: theme.spacing(2),
    paddingBottom: theme.spacing(3)
  },
}));

// Administration of currently logged in user.
export interface User {
  userid: number;
  username: string;
  realname: string;
  token: string | null;
  role: string;
  email: string;
  tokenexpirytime: Date;
}
function App() {

  const classes = useStyles();

  const [user, setUser] = useState<User>({
    userid: 0,
    username: "",
    realname: "",
    token: null,
    role: "",
    email: "",
    tokenexpirytime: new Date(),
  });

  const [aboutOpen, setAboutOpen] = useState<boolean>(false);

  let { pathname } = useLocation();

  const openAbout = () => {
    setAboutOpen(true);
  }

  const closeAbout = () => {
    setAboutOpen(false);
  }

  return (
    <div className="Planner">
      <header className="App-header">
        <AppBar color="secondary" position="static">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" onClick={openAbout}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              ROYAL TEAZER PLANNER
            </Typography>
            
            <Link to="/calendar">
              <Button
                className={classes.buttoninput}
                color="secondary"
                variant="contained"
                disableElevation={pathname.startsWith("/calendar")}>
                  Kalender
              </Button>
            </Link>

            <Link to="/sheets">
              <Button
                className={classes.buttoninput}
                color="secondary"
                variant="contained"
                disableElevation={pathname.startsWith("/sheets")}>
                  Nummers
              </Button>
            </Link>

            <Link to="/audio">
              <Button
                className={classes.buttoninput}
                color="secondary"
                variant="contained"
                disableElevation={pathname.startsWith("/audio")}>
                  Audio
              </Button>
            </Link>

            <div className={classes.user}>
              <Login user={user} onSetUser={setUser} />
            </div>
          </Toolbar>
        </AppBar>
        <About isOpen={aboutOpen} onClose={closeAbout} />
      </header>

          <Routes>
            <Route path="/" element={
              <Paper className={classes.paper} elevation={3}>
                <h3 className={classes.header}>Welkom op de Royal Teazer Planner voor Members Only</h3>
                <p className={classes.paragraph}>Klik op een knop hierboven om naar de juiste pagina te gaan. Als je dingen wilt aanpassen, 
                vergeet dan niet om in te loggen met de LOGIN knop rechtsboven.</p>
              </Paper>
            }/>
            <Route path="/calendar" element={
              <Calendar user={user} />
            }/>
            <Route path="/sheets/*" element={
              <Songs user={user}/>
            }/>
            <Route path="/audio/*" element={
              <Audio user={user}/>
            }/>
          </Routes>

        <Typography className={classes.footer}>Deze site is gemaakt door <LinkBase href="https://www.twisq.nl" color="inherit">TWISQ</LinkBase>.</Typography>
    </div>
  );
}

export default App;

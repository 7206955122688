import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridSortDirection, GridStateChangeParams, GridValueFormatterParams } from '@material-ui/data-grid';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ButtonGroup } from "@material-ui/core";
import MusicNote from "@material-ui/icons/MusicNote";
import { User } from "./App";
import SetEvents from "./SetEvents";

import config from "./config.json";
import { useFeedback } from "./Feedback";
import { HTTP, HTTPMethod } from "./Backend";

interface CalendarProperties {
    user: User;
}

const useStyles = makeStyles((theme: Theme) => ({
    calendar: {
        margin: theme.spacing(1)
    },
    datagrid: {
        color: theme.palette.text.secondary
    },
    available: {
        color: theme.palette.success.main
    },
    notavailable: {
        color: theme.palette.error.main
    }
}));

export interface CalanderItem {
    id: string;         // The date in string notatation is the key.
    weekday: string;
    rehearsal: string;
    available: string;
    notavailable: string;
    fromtime: string;
    totime: string;
    comments: string;
}

const getWeekday = (date: Date): string => {
    const daynumber = date.getDay();
    let day:string = "";
    if (daynumber === 0) {
        day = "zondag";
    } else if (daynumber === 1) {
        day = "maandag";
    } else if (daynumber === 2) {
        day = "dinsdag";
    } else if (daynumber === 3) {
        day = "woensdag";
    } else if (daynumber === 4) {
        day = "donderdag";
    } else if (daynumber === 5) {
        day = "vrijdag";
    } else if (daynumber === 6) {
        day = "zaterdag";
    }
    return day;
}

const getDatestring = (date: Date): string => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return date.getFullYear() + "-" + (month < 10 ? "0" : "") + month + "-" + (day < 10 ? "0" : "") + date.getDate();
}

const getDateInt = (date: Date): number => {
    return date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate();
}


function Calendar(props: CalendarProperties) {

  const classes = useStyles();

  const [busy, setBusy] = useState<boolean>(false);
  const [calendar, setCalendar] = useState<CalanderItem[]>([]);
  const [selection, setSelection] = useState<string[]>([]);
  const [reloadCount, setReloadCount] = useState<number>(0);

  const feedback = useRef(useFeedback());

  useEffect(() => {
        setBusy(true);
        HTTP(HTTPMethod.GET, config.urlCalendar)
        .then(json => {
                let newCalendar: CalanderItem[] = [];
                let filledInDates: number[] = [];

                // Parse results from days API.
                for (let record of json) {
                    const recorddate = new Date(record.date);
                    const row:CalanderItem = {
                      id: getDatestring(recorddate),
                      comments: record.comments,
                      rehearsal: (record.rehearsal ? "Oefenen" : ""),
                      weekday: getWeekday(recorddate),
                      available: record.available,
                      notavailable: record.notavailable,
                      fromtime: record.fromtime,
                      totime: record.totime
                    };
                    filledInDates.push(getDateInt(recorddate));
                    newCalendar.push(row);
                }

                // Add empty records.
                let date = new Date();
                for (let i = 0; i < config.numberOfDaysInFuture; i++) {
                    const weekday = date.getDay();
                    if ((config.visibleWeekdays.indexOf(weekday) >= 0) && (filledInDates.indexOf(getDateInt(date)) < 0)) {
                        const row:CalanderItem = {
                            id: getDatestring(date),
                            comments: "",
                            rehearsal: "",
                            weekday: getWeekday(date),
                            available: "",
                            notavailable: "",
                            fromtime: "",
                            totime: ""
                          };
                          newCalendar.push(row);
                    }
                    date.setDate(date.getDate() + 1);
                }
                setCalendar(newCalendar);

        })
        .catch(error => {
            feedback.current(error);
        })
        .finally(() => {
            setBusy(false);
        });
  }, [reloadCount]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "Datum", width: 120 },
    { field: "weekday", headerName: "Dag", width: 90 },
    { field: "rehearsal", headerName: "Oefenen", width: 130,
        renderCell: (params: GridValueFormatterParams) => (
            <span>
                {params.value !== "" && (<><MusicNote/>{params.value}</>)}
            </span>
        ),
    },
    { field: "availability", headerName: "Beschikbaarheid", width: 320,
      renderCell: (params: GridValueFormatterParams) => (
        <span>
            <span className={classes.available}>
                {params.row.available}
            </span>
            {(params.row.available !== "") && (params.row.notavailable) && (<span> / </span>)}
            <span className={classes.notavailable}>
            {params.row.notavailable}
            </span>
        </span>
      )
    },
    { field: "fromtime", headerName: "Vanaf", width: 90 },
    { field: "totime", headerName: "Tot", width: 90 },
    { field: "comments", headerName: "Opmerkingen", width: 800}
  ];


const handleReloadRequest = () => {
    setReloadCount(reloadCount + 1);
}

const handleSelectionChange = (param: GridStateChangeParams): void => {
    setSelection(param.state.selection as string[]);
}

return (
      <section className={classes.calendar}>

        <div style={{ height: 800, width: '100%' }}>
            <DataGrid
            className={classes.datagrid}
            loading={busy}
            rows={calendar} 
            columns={columns} 
            autoPageSize
            checkboxSelection={props.user.token !== null} 
            sortModel={[
                {
                    field: 'id',
                    sort: 'asc' as GridSortDirection,
                }]}
            onStateChange={handleSelectionChange}
            />
        </div>

        <ButtonGroup>
            <SetEvents variant="SetAvailable" user={props.user} selectedDates={selection} onReloadRequest={handleReloadRequest}/>
            <SetEvents variant="SetNotAvailable" user={props.user} selectedDates={selection} onReloadRequest={handleReloadRequest}/>
            <SetEvents variant="SetRehearse" user={props.user} selectedDates={selection} onReloadRequest={handleReloadRequest}/>
            <SetEvents variant="SetNotRehearse" user={props.user} selectedDates={selection} onReloadRequest={handleReloadRequest}/>
        </ButtonGroup>

      </section>
  );
}

export default Calendar;

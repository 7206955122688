import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import ShowAttachments from "./ShowAttachments";
import config from "./config.json";
import { Link, useParams } from "react-router-dom";
import { useFeedback } from "./Feedback";
import { HTTPMethod, HTTPText } from "./Backend";


const useStyles = makeStyles((theme: Theme) => ({
  error: {
      color: theme.palette.error.main
  },
  buttoninput: {
    margin: theme.spacing(1),
    float: "right"
  },
  accountCircle: {
    marginRight: theme.spacing(1)
  }
}));


function ShowSong() {

  const classes = useStyles();

  const [content, setContent] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(false);
  
  const feedback = useRef(useFeedback());

  let { songid } = useParams<{songid:string}>();
  
  useEffect(() => {
        setBusy(true);
        HTTPText(HTTPMethod.GET, config.urlGetHtmlSong + "/" + songid)
        .then(text => {
            setContent(text);
        })
        .catch(error => {
          feedback.current(error);
        })
        .finally(() => {
            setBusy(false);
        });
  }, [songid]);

  const songid_string = (songid === undefined) ? null : parseInt(songid);
  return (
    <div>
            <Paper dangerouslySetInnerHTML={{__html: content}}>
            </Paper>
            <ShowAttachments songid={songid_string} />

                {busy && (
                   <div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>
                      <CircularProgress />
                  </div>
                )}

            <Link to="/sheets">
              <Button
                  className={classes.buttoninput}
                  color="secondary"
                  variant="contained">
                      Sluiten
              </Button>
            </Link>
      </div>
  );
}

export default ShowSong;
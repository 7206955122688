import React, { useEffect, useRef, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { DataGrid, GridColDef, GridSortDirection, GridValueFormatterParams } from '@material-ui/data-grid';
import EditIcon from "@material-ui/icons/Edit";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import DeleteIcon from "@material-ui/icons/Delete";
import ShowIcon from "@material-ui/icons/Description";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { User } from "./App";
import EditSong from "./EditSong";
import ShowSong from "./ShowSong";

import config from "./config.json";
import { useFeedback } from "./Feedback";
import { HTTP, HTTPMethod } from "./Backend";
import ConfirmDialog from "./ConfirmDialog";

interface SongProperties {
    user: User;
}

const useStyles = makeStyles((theme: Theme) => ({
    song: {
        margin: theme.spacing(1)
    },
    datagrid: {
        color: theme.palette.text.secondary
    },
    buttoninput: {
        margin: theme.spacing(1)
    }    
}));

export interface SongItem {
    id: number;
    songname: string;
    date: Date;
    comments: string;
}

function Song(props: SongProperties) {

  const classes = useStyles();

  const [busy, setBusy] = useState<boolean>(false);
  const [songs, setSongs] = useState<SongItem[]>([]);
  const [currentSongId, setCurrentSongId] = useState<number | null>(null);
  const [reloadRequest, setReloadRequest] = useState<boolean>(true);
  const [deleteCandidateId, setDeleteCandidateId] = useState<number | null>(null);

  const feedback = useRef(useFeedback());
  
  useEffect(() => {
        if (reloadRequest) {
            setBusy(true);
            HTTP(HTTPMethod.GET, config.urlSongs)
            .then(json => {
                    let newSongs: SongItem[] = [];
                    for (let record of json) {
                        const row:SongItem = {
                        id: record.songid,
                        songname: record.songname,
                        date: record.date,
                        comments: record.comments
                        };
                        newSongs.push(row);
                    }
                    setSongs(newSongs);
            })
            .catch(error => {
                feedback.current(error);
            })
            .finally(() => {
                setBusy(false);
                setReloadRequest(false);
            });
        }
  }, [reloadRequest]);

  const deleteSong = (id: number | null) => {
    setBusy(true);
    HTTP(HTTPMethod.DELETE, config.urlDeleteSong + '/' + id, props.user.token)
    .then(() => {
        setReloadRequest(true);
    })
    .catch(error => {
        feedback.current(error);
    })
    .finally(() => {
        setBusy(false);
    });
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "#", width: 100 },
    { field: "songname", headerName: "Nummer", width: 240 },
    { field: "date", headerName: "Datum", width: 120 },
    { field: "comments", headerName: "Opmerkingen", width: 360 },
    { field: "action", headerName: "Actie", width: 240, 
      renderCell: (params: GridValueFormatterParams) => (
        <span>
          <Tooltip title="Bewerk akkoordenschema">
            <span>
                <IconButton
                    color="secondary"
                    className={classes.buttoninput}
                    disabled={props.user.token === null}
                    onClick={() => {setCurrentSongId(params.row.id as number);}}
                > 
                    <EditIcon/>
                </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Akkoordenschema bekijken">
            <Link to={"/sheets/" + params.row.id}>
              <IconButton
                color="secondary"
                className={classes.buttoninput}
              > 
                <ShowIcon/>
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Download akkoordenschema als PDF">
            <IconButton
                color="secondary"
                className={classes.buttoninput}
                href={config.urlGetPdfSong + "/" + params.row.id}
            > 
                <PdfIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Verwijder dit nummer">
            <IconButton
                color="secondary"
                className={classes.buttoninput}
                disabled={props.user.token === null}
                onClick={() => setDeleteCandidateId(params.row.id as number)}
            >
                <DeleteIcon/>    
            </IconButton>
          </Tooltip>
        </span>
      )
    }
  ];


  const handleAddSong = () => {
      setCurrentSongId(0);
  }

  const handleCloseEditDialog = () => {
      setReloadRequest(true);  // Force reload of the data.
      setCurrentSongId(null);
  }

  return (
      <section className={classes.song}>

        <Routes>
            <Route path="/" element={
                <>
                <EditSong songid={currentSongId} user={props.user} onClose={handleCloseEditDialog}/>

                <div style={{ height: 800, width: '100%' }}>
                    <DataGrid
                    className={classes.datagrid}
                    loading={busy}
                    rows={songs} 
                    columns={columns}
                    autoPageSize
                    sortModel={[
                        {
                            field: 'songname',
                            sort: 'asc' as GridSortDirection,
                        }]}
                    />
                </div>

                <Button
                    className={classes.buttoninput}
                    color="secondary"
                    variant="contained"
                    disabled={props.user.token === null}
                    onClick={handleAddSong}>
                        Nieuw nummer toevoegen
                </Button>

                <Button 
                    className={classes.buttoninput}
                    color="secondary"
                    variant="contained"
                    href={config.urlSongbook}>
                        Download songbook
                </Button>
                </>
            }/>
            
            <Route path=":songid" element={<ShowSong/>}/>
        </Routes>

        <ConfirmDialog
            title="Bevestig verwijderen nummer"
            open={deleteCandidateId !== null}
            onConfirm={() => {deleteSong(deleteCandidateId)}}
            onClose={() => setDeleteCandidateId(null)}
        >
            Weet je zeker dat je dit nummer wilt verwijderen?
        </ConfirmDialog>

      </section>
  );
}

export default Song;

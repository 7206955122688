import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";

import { User } from "./App";
import EditAttachments from "./EditAttachments";

import config from "./config.json";
import { useFeedback } from "./Feedback";
import { HTTP, HTTPMethod } from "./Backend";

const defaultcrd:string = 
  "{artist: Royal Teazer}\n" +
  "{key: C}\n" +
  "{tempo: 120}\n" +
  "\n" +
  "{comment: Zo kan je opmerkingen invoeren}\n" +
  "\n" +
  "{start_of_verse}\n" +
  "Type hier het couplet\n" +
  "Voeg [C]akkoorden toe [F]in de [G]regels\n" +
  "{end_of_verse}\n" +
  "\n" +
  "{start_of_chorus}\n" +
  "Hetzelfde voor het refrein\n" +
  "[Em]la la la la la [Am]yeah\n" +
  "{end_of_chorus}\n" +
  "\n" +
  "{start_of_tab}\n" +
  "+---------------------------------------------------------+\n" +
  "+---------------------------------------------------------+\n" +
  "+---------------------------------------------------------+\n" +
  "+---------------------------------------------------------+\n" +
  "+---------------------------------------------------------+\n" +
  "+---------------------------------------------------------+\n" +
  "{end_of_tab}\n";

interface EditSongProperties {
  songid: number | null;
  user: User;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
      color: theme.palette.error.main
  },
  textinput: {
    display: "block",
    margin: theme.spacing(1),
  },
  monofont: {
    fontFamily: "Courier New, Courier, monospace"
  },
  buttoninput: {
    margin: theme.spacing(1)
  },
  accountCircle: {
    marginRight: theme.spacing(1)
  }
}));


function EditSong(props: EditSongProperties) {

  const classes = useStyles();

  const [songname, setSongname] = useState<string>("New song");
  const [comments, setComments] = useState<string>("");
  const [newAttachments, setNewAttachments] = useState<number[]>([]);
  const [chords, setChords] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(false);

  const feedback = useRef(useFeedback());

  useEffect(() => {
    if (props.songid === 0) {
        setSongname("Nieuwe titel");
        setComments("");
        setChords(defaultcrd);
    } else if (props.songid !== null) {
        setBusy(true);
        HTTP(HTTPMethod.GET, config.urlGetSong + "/" + props.songid)
        .then(json => {
            setSongname(json.songname);
            setComments((json.comments !== null) ? json.comments : "");
            setChords(json.chords);
        })
        .catch(error => {
            feedback.current(error);
        })
        .finally(() => {
            setBusy(false);
        });

    }
    setNewAttachments([]);
  }, [props.songid]);

  const handleAddAttachment = (attachmentid:number) => {
    let ats = newAttachments;
    ats.push(attachmentid);
    setNewAttachments(ats);
  }

  const handleDeleteAttachment = (attachmentid:number) => {
    let ats = newAttachments;
    setNewAttachments(ats.filter((v:number) => { return (v !== attachmentid)}));
  }

  // User has clicked on Save.
  const handleSave = () => {
    setBusy(true);
    const contents = {
      "songid": props.songid,
      "songname": songname,
      "chords": chords,
      "comments": comments
    };
    HTTP(HTTPMethod.POST, config.urlSaveSong, props.user.token, contents)
    .then((json) => {
        if (((props.songid === null) || (props.songid ===0)) && (newAttachments.length > 0)) {
          // If this is a new song, connect the attachments, if any, to the newly created song.
          claimAttachments(json.songid);
        } else {
          props.onClose();
        }
    })
    .catch(error => {
      feedback.current(error);
    })
    .finally(() => {
      setBusy(false);
    });
  };

  // Claim attachments for song.
  const claimAttachments = (songid:number) => {
    const contents = {
      "songid": songid,
      "attachmentids": newAttachments
    };
    HTTP(HTTPMethod.POST, config.urlClaimAttachments, props.user.token, contents)
    .then(() => {
        props.onClose();
    })
    .catch(error => {
      feedback.current(error);
    })
    .finally(() => {
      setBusy(false);
    });
  };

  const handleSongnameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSongname(event.target.value);
  };

  const handleCommentsInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };

  const handleChordsInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChords(event.target.value);
  };

return (
    <div>

        <Dialog fullWidth maxWidth="lg" open={props.songid !== null} aria-labelledby="form-dialog-title" onClose={props.onClose}>
          <DialogTitle id="form-dialog-title">Nummer bewerken</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogContentText>
              Bewerk de tekst en akkoorden hieronder en klik op &quot;Opslaan&quot; als je klaar bent.
            </DialogContentText>

                {busy && (
                   <div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>
                      <CircularProgress />
                  </div>
                )}

                <Grid container spacing={3}>
                  <Grid item xs={9}>
                    <TextField
                      className={classes.textinput}
                      id="songname"
                      label="Titel"
                      variant="outlined"
                      fullWidth
                      disabled={busy}
                      value={songname}
                      onChange={handleSongnameInputChange}
                    />

                    <TextField
                      className={classes.textinput}
                      id="comments"
                      label="Opmerkingen"
                      variant="outlined"
                      fullWidth
                      disabled={busy}
                      value={comments}
                      onChange={handleCommentsInputChange}
                    />

                    <TextField
                      className={classes.textinput}
                      id="chords"
                      label="Akkoordenschema"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={32}
                      disabled={busy}
                      value={chords}
                      onChange={handleChordsInputChange}
                      InputProps={{
                        classes: {
                          input: classes.monofont,
                        },
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={3}>
                    <EditAttachments songid={props.songid} user={props.user} onAddAttachment={handleAddAttachment} onDeleteAttachment={handleDeleteAttachment}/>
                  </Grid>

                </Grid>

              </DialogContent>
              <DialogActions>
                <Button
                    className={classes.buttoninput}
                    color="secondary"
                    variant="contained"
                    onClick={props.onClose}>
                      Annuleer
                  </Button>
                  <Button
                    className={classes.buttoninput}
                    color="secondary"
                    variant="contained"
                    disabled={props.user.token === null}
                    onClick={handleSave}>
                      Opslaan
                  </Button>
                </DialogActions>
        </Dialog>
      </div>
  );
}

export default EditSong;
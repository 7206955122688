import React, { useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import AccountCircle from '@material-ui/icons/AccountCircle';

import { User } from "./App";
import EditUser from "./EditUser";
import EditPassword from "./EditPassword";

import config from "./config.json";
import { useFeedback } from "./Feedback";
import { HTTP, HTTPMethod } from "./Backend";

type onSetUserHandler = (user: User) => void;

interface LoginProperties {
  user: User;
  onSetUser: onSetUserHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrong: {
    display: "block",
    margin: theme.spacing(2),
    color: "red"
  },
  login: {
    display: "block",
    margin: theme.spacing(2),
  },
  loginButton: {
    color: "white",
    margin: theme.spacing(1)
  },
  textinput: {
    display: "block",
    margin: theme.spacing(1),
    width: 400
  },
  buttoninput: {
    margin: theme.spacing(1)
  },
  accountCircle: {
    marginRight: theme.spacing(1)
  }
}));


function Login(props: LoginProperties) {

  const classes = useStyles();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [dialogOpen, setdialogOpen] = useState<boolean>(false);
  const [showUser, setShowUser] = useState<boolean>(false);
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

  const feedback = useRef(useFeedback());

  const handleLoginButton = () => {
    setdialogOpen(true);
  }

  const handleCancelButton = () => {
    setdialogOpen(false);
    setBusy(false);
  }

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  }

  const handleEditUser = () => {
    setShowUser(true);
    handleCloseMenu();
  }

  const handleEditPassword = () => {
    setEditPassword(true);
    handleCloseMenu();
  }

  const handleLogout = () => {
    const nouser:User = {
      userid: 0,
      username: "",
      realname: "",
      email: "",
      role: "",
      token: null,
      tokenexpirytime: new Date()
    }
    props.onSetUser(nouser);
    handleCloseMenu();
  }

  // Handle keypresses during login phase.
  const handleUsernameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUsername(event.target.value);
  };
  const handlePasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
  };

  // User has entered her credentials. Test if they are valid.
  const handleLogin = () => {
    setBusy(true);
    const contents = ({
      "username": username,
      "password": password
    });
    HTTP(HTTPMethod.POST, config.urlLogin, props.user.token, contents)
    .then(json => {
        props.onSetUser({
          userid: json.userid,
          username: username,
          realname: json.realname,
          role: json.role,
          token:  json.token,
          email: json.email,
          tokenexpirytime: new Date(json.tokenexpirytime)
        });
        setdialogOpen(false);
    })
    .catch(error => {
      feedback.current("Combinatie van gebruikersnaam en wachtwoord is ongeldig.");
    })
    .finally(() => {
      setBusy(false);
    });
  };

  // Catch enter keypress.
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if ((event.key === "Enter") && (username !== "") && (password !== "")) {
      handleLogin();
    }
  }

  return (
    <div>
        {
        (props.user.token === null) && (
          <Button className={classes.loginButton} color="secondary" variant="contained" onClick={handleLoginButton}>Login</Button>
        )}

        {
        (props.user.token !== null) && (
            <div>
              <Button className={classes.loginButton} color="secondary" variant="contained" onClick={handleOpenMenu}>
                <AccountCircle className={classes.accountCircle}/>
                {props.user.realname}
              </Button>
              <Menu
                id="user-menu"
                anchorEl={anchorElement}
                keepMounted
                open={Boolean(anchorElement)}
                onClose={handleCloseMenu}
              >
              <MenuItem onClick={handleEditUser}>Gebruikersprofiel</MenuItem>
              <MenuItem onClick={handleEditPassword}>Wachtwoord aanpassen</MenuItem>
              <MenuItem onClick={handleLogout}>Uitloggen</MenuItem>
            </Menu>
          </div>
        )}

        <EditUser 
          user={props.user} 
          show={showUser} 
          onSetUser={(user) => {props.onSetUser(user);}}
          onClose={() => {setShowUser(false);}}
        />

        <EditPassword
          user={props.user}
          show={editPassword}
          onClose={() => {setEditPassword(false);}}
        />

        <Dialog open={dialogOpen} aria-labelledby="form-dialog-title" onClose={handleCancelButton} onKeyPress={handleKeyPress}>
          <DialogTitle id="form-dialog-title">Login</DialogTitle>
          <DialogContent>
            <DialogContentText>Geef je naam en wachtwoord op:</DialogContentText>

                <TextField
                  className={classes.textinput}
                  id="username"
                  label="Gebruikersnaam"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  autoComplete="off"
                  disabled={busy}
                  onChange={handleUsernameInputChange}
                />
                <TextField
                  className={classes.textinput}
                  id="password"
                  label="Wachtwoord"
                  type="password"
                  variant="outlined"
                  fullWidth
                  disabled={busy}
                  onChange={handlePasswordInputChange}
                />
                        
                {busy && (
                   <div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>
                      <CircularProgress />
                  </div>
                )}

              </DialogContent>
              <DialogActions>
                <Button
                    className={classes.buttoninput}
                    color="secondary"
                    variant="contained"
                    onClick={handleCancelButton}>
                      Annuleer
                  </Button>
                  <Button
                    className={classes.buttoninput}
                    color="secondary"
                    variant="contained"
                    disabled={(username === "") || (password === "")}
                    onClick={handleLogin}>
                      Login
                  </Button>
                </DialogActions>
        </Dialog>
      </div>
  );
}

export default Login;